import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./cancelsubfinal.scss";
import { getReason } from "../../../../services/subService";
import { getProductDisplayName } from "../../../../util/product";
import { withTranslation } from "react-i18next";

class CancelSubFinal extends Component {
  state = {
    reason: "",
    freeReason: "",
  };

  async componentDidMount() {
    const index = this.props.customer.subs.findIndex(
      (x) => x._id === this.props.match.params.id
    );
    if (index >= 0) {
      const sub = this.props.customer.subs[index];

      let tempProd = getProductDisplayName(sub.product.name);

      const reason = await getReason(tempProd, this.props.match.params.reason);
      this.setState({ reason });
    }
  }

  onSubCancel = async (e) => {
    e.preventDefault();

    const reason = this.props.match.params.reason;
    const subId = this.props.match.params.id;
    if (this.state.freeReason) {
      await this.props.handleCancelUnsubFree(
        subId,
        this.state.freeReason,
        this.state.reason.category
      );
    } else {
      await this.props.handleCancelUnsub(
        subId,
        reason,
        this.state.reason.category
      );
    }

    this.props.history.push(
      "/portal/endre/" + subId + "/avslutt/" + reason + "/avsluttet"
    );
  };

  onCancel = () => {
    this.props.handleCancel();
    this.props.history.push("/portal");
  };

  onReasonChange = (e) => {
    const { value } = e.currentTarget;
    let freeReason = this.state.freeReason;
    freeReason = value;
    this.setState({ freeReason });
  };

  render() {
    const reason = this.state.reason;
    const { t } = this.props;

    return (
      <div className="editsub-wrapper">
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <p>{t(reason.text)}</p>
            {reason.id === 9999 && (
              <p>
                <textarea
                  rows="10"
                  cols="50"
                  onChange={this.onReasonChange}
                  value={this.state.freeReason}
                ></textarea>
              </p>
            )}
            <button className="btn btn-link" onClick={this.onCancel}>
              {t('continueWithDiscount')}
            </button>
            <button className="btn btn-link red" onClick={this.onSubCancel}>
              {t('cancelAnyway')}
            </button>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12"></Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(CancelSubFinal);
