import React, { Component } from "react";
import "./page404.scss";

class Page404 extends Component {
  render() {
    return <div className="page404-wrapper">404 Page not found</div>;
  }
}

export default Page404;
