export const getProductDisplayName = (productName) => {
  if (productName.includes("Hair And Skin")) return "Hair And Skin";
  else if (productName.includes("Mory")) return "Mory";
  else if (productName.includes("Complex")) return "T-Complex";
  else if (productName.includes("Energy")) return "Energy B12D3";
  else if (productName.includes("Craveless")) return "Craveless";
  else if (productName.includes("Melatonin Vital")) return "Melatonin Vital";
  else if (productName.includes("Collagen Vital")) return "Collagen Vital";
  else if (productName.includes("Collagen Multi")) return "Collagen Multi";
  else if (productName.includes("Q22")) return "Q22";
  else if (productName.includes("ProbiForte")) return "BioBelly";
  else if (productName.includes("BioBelly")) return "BioBelly";
  else if (productName.includes("SeaTrue")) return "SeaTrue";
  else if (productName.includes("FLEXYPRO")) return "FLEXYPRO";
  else if (productName.includes("MemRY")) return "MemRY";
  else if (productName.includes("SLEEPMORE")) return "SLEEPMORE";
  else if (productName.includes("Mello17")) return "Mello17";
  else if (productName.includes("Retine")) return "Retine Optivision";
  else if (productName.includes("MEDINIGHT")) return "MEDINIGHT";
  else if (productName.includes("hues Glow")) return "hues Glow";
  else if (productName.includes("Estrodoxin")) return "Estrodoxin";
  else if (productName.includes("Enzytin")) return "Enzytin";
  else if (productName.includes("Summit Magnesium")) return "Summit Magnesium";
  else return productName;
};
