import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./person.scss";

class Person extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingPerson: false,
      error: {},
    };
  }

  validatePerson = () => {
    let error = null;
    const { t } = this.props;
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.props.customer.name.trim() === "") {
      error = {
        name: t("invalidName"),
      };
    } else if (this.props.customer.street.trim() === "") {
      error = {
        street: t("invalidAddress"),
      };
    } else if (this.props.customer.postcode.trim() === "") {
      error = {
        postcode: t("invalidPostalCode"),
      };
    } else if (this.props.customer.postarea.trim() === "") {
      error = {
        postarea: t("invalidPostalPlace"),
      };
    } else if (this.props.customer.email.trim() !== "") {
      if (!re.test(String(this.props.customer.email).toLowerCase())) {
        error = {
          email: t("invalidEmail"),
        };
      }
    }
    return error;
  };

  onCancel = () => {
    this.props.handleCancel();
    this.setState({ editingPerson: false });
  };

  onEditPerson = () => {
    this.setState({ editingPerson: true });
  };

  onInputChange = (e) => {
    const { name, value } = e.currentTarget;
    this.props.handleInputChange(name, value);
  };

  onSubmit = (e) => {
    e.preventDefault();

    let error = this.validatePerson();
    this.setState({ error });
    if (error) return;

    this.props.handleUpdatePerson();
    this.setState({ editingPerson: false });
  };

  render() {
    const { customer, t } = this.props;
    if (!this.state.editingPerson)
      return (
        <div className="person-wrapper">
          <h4>
            {t("personalInformation")}
            {this.props.customer.country === "no" && (
              <button className="btn btn-link" onClick={(e) => this.onEditPerson(e)}>
                {t("change")}
              </button>
            )}
          </h4>
          <table className="person-table">
            <tbody>
              <tr>
                <td>{t("name")}:</td>
                <td>{customer.name}</td>
              </tr>
              <tr>
                <td>{t("customerNumber")}:</td>
                <td>{customer.id}</td>
              </tr>
              <tr>
                <td>{t("phoneNumber")}:</td>
                <td>{customer.phone}</td>
              </tr>
              <tr>
                <td>{t("address")}:</td>
                <td>{customer.street}</td>
              </tr>
              <tr>
                <td>{`${t("postalCode")}/${t("postalPlace")}`}:</td>
                <td>
                  {customer.postcode} {customer.postarea}
                </td>
              </tr>
              <tr>
                <td>{t("email")}:</td>
                <td>{customer.email}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    else
      return (
        <div className="person-wrapper">
          <h4>{t("Endre personopplysninger")}</h4>
          <form className="person-form" onSubmit={this.onSubmit}>
            <div className="person-form-div">
              <label htmlFor="name">{t("Navn")}</label>
              <input name="name" type="text" size="10" value={customer.name} onChange={this.onInputChange} />
              <div className="person-form-div-error">{this.state.error && this.state.error.name}</div>
            </div>
            <div className="person-form-div">
              <label htmlFor="street">{t("address")}</label>
              <input name="street" type="text" size="10" value={customer.street} onChange={this.onInputChange} />
              <div className="person-form-div-error">{this.state.error && this.state.error.street}</div>
            </div>
            <div className="person-form-div">
              <label htmlFor="postcode">{t("postalCode")}</label>
              <input name="postcode" type="text" size="10" value={customer.postcode} onChange={this.onInputChange} />
              <div className="person-form-div-error">{this.state.error && this.state.error.postcode}</div>
            </div>
            <div className="person-form-div">
              <label htmlFor="postarea">{t("postalPlace")}</label>
              <input name="postarea" type="text" size="10" value={customer.postarea} onChange={this.onInputChange} />
              <div className="person-form-div-error">{this.state.error && this.state.error.postarea}</div>
            </div>
            <div className="person-form-div">
              <label htmlFor="email">{t("email")}</label>
              <input name="email" type="text" size="10" value={customer.email} onChange={this.onInputChange} />
              <div className="person-form-div-error">{this.state.error && this.state.error.email}</div>
            </div>
            <div className="person-form-buttons">
              <button className="btn btn-success" type="submit">
                {t("save")}
              </button>
              <button className="btn btn-danger" type="button" onClick={this.onCancel}>
                {t("undo")}
              </button>
            </div>
          </form>
        </div>
      );
  }
}

export default withTranslation()(Person);
