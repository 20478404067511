import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Page404 from "./components/common/page404/Page404";
import Login from "./components/login/Login";
import LoginSms from "./components/login/LoginSms";
import Logout from "./components/login/Logout";
import Portal from "./components/portal/Portal";

import "./App.scss";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { Suspense } from "react";

class App extends Component {
  render() {
    return (
      <Suspense fallback={<i className="fas fa-spinner fa-spin"></i>}>
        <ToastContainer />
        <div className="main-grid">
          <Switch>
            <Route path="/portal" render={(props) => <Portal {...props} />} />
            <Route path="/code" exact render={(props) => <LoginSms {...props} />} />
            <Route path="/logout" exact render={(props) => <Logout {...props} />} />
            <Route path="/" exact render={(props) => <Login {...props} />} />
            <Route render={(props) => <Page404 {...props} />} />
          </Switch>
        </div>
      </Suspense>
    );
  }
}

export default withRouter(App);
