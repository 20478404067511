import React from "react";

import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const LanguageChooser = (props) => {
  const setLanguage = (lng) => {
    props.i18n.changeLanguage(lng);
  };

  return (
    <Dropdown style={{ display: "inline-block" }}>
      <Dropdown.Toggle variant="outline-secondary" size="sm" id="language-chooser">
        Language
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setLanguage("nb")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Norsk flagg">
            🇳🇴
          </span>
          Norsk
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("sv")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Svensk flagga">
            🇸🇪
          </span>
          Svenska
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("da")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Dansk flagg">
            🇩🇰
          </span>
          Dansk
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("en")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Britsk flagg">
            🇬🇧
          </span>
          English
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("fi")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Suomen lippu">
            🇫🇮
          </span>
          Suomi
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("nl")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Nederlandse vlag">
            🇳🇱
          </span>
          Nederlands
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("de")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Deutsche Flagge">
            🇩🇪
          </span>
          Deutsch
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("fr")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Drapeau français">
            🇫🇷
          </span>
          Française
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("pl")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Polska flaga">
            🇵🇱
          </span>
          Polski
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("pt")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Portugal flagg">
            🇵🇹
          </span>
          Portugal
        </Dropdown.Item>
        <Dropdown.Item onClick={() => setLanguage("cz")}>
          <span className="nl-language-chooser__flag" role="img" aria-label="Česká vlajka">
            🇨🇿
          </span>
          Český
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withTranslation()(LanguageChooser);
