import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./cancelsub.scss";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";

import { getProductDisplayName } from "../../../../util/product";

class CancelSub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [],
      reason: 9999,
    };
  }

  handleCancelNext = (e) => {
    e.preventDefault();
    if (this.state.reason === 0) {
      toast.info(this.props.t(""));
    } else {
      this.props.history.push("/portal/endre/" + this.props.match.params.id + "/avslutt/" + this.state.reason);
    }
  };

  handleChangeUnsubReason = (e) => {
    let { reason } = this.state;
    if (e.target.value) {
      reason = parseInt(e.target.value);
    }
    this.setState({ reason });
  };

  onCancel = () => {
    this.props.handleCancel();
    this.props.history.push("/portal");
  };

  render() {
    const subId = this.props.match.params.id;
    const index = this.props.customer.subs.findIndex((x) => x._id === subId);
    let sub = {};
    if (index >= 0) {
      sub = this.props.customer.subs[index];
    }

    const { t } = this.props;
    return (
      <div className="editsub-wrapper">
        <React.Fragment>
          <Row style={{ textAlign: "center" }}>
            <Col className="mb-3 mt-3 col-12">
              <h4>{t("unsubStep1", { product: sub.product && sub.product.name && getProductDisplayName(sub.product.name) })}</h4>
              {sub.product && sub.product.name === "Melatonin Vital" ? (
                <div>
                  <p>
                    <strong>{t("mvTitle")}</strong>
                  </p>
                  <p>
                    <img className="image-box" src="/images/melatonin-vital-eske.jpg" alt="Melatonin Vital" />
                  </p>
                  <p>{t("mvDescription")}</p>
                </div>
              ) : sub.product && sub.product.name === "Collagen Vital" ? (
                <div>
                  <p>
                    <img className="image-box" src="/images/collagen-vital-eske.jpg" alt="Collagen Vital" />
                  </p>
                  <p>{t("cvDescription")}</p>
                </div>
              ) : (
                <p></p>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-3 mt-3 col-12">
              <form className="editsub-form" onSubmit={this.handleCancelNext}>
                <div className="editsub-form-div">{t("chooseUnsubReason")}</div>
                <div className="editsub-form-buttons">
                  <button type="submit" className="btn btn-success">
                    {t("nextPage")}
                  </button>
                </div>
              </form>
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col className="mb-3 mt-3 col-12">
              <button className="btn btn-link" onClick={this.onCancel}>
                {t("toMainPage")}
              </button>
            </Col>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}

export default withTranslation()(CancelSub);
