import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./editsub.scss";
import date from "date-and-time";
import { getProductDisplayName } from "../../../../util/product";
import { withTranslation } from "react-i18next";

class EditSub extends Component {
  onShipmentDateSave = (e) => {
    e.preventDefault();
    const index = this.props.customer.subs.findIndex((x) => x._id === this.props.match.params.id);

    if (index >= 0) {
      const sub = this.props.customer.subs[index];

      let lastOrder = sub.orders[sub.orders.length - 1];

      this.props.handleSaveNewShipmentDate(lastOrder);

      this.props.history.push("/portal");
    }
  };

  onShipmentDateChange = (e) => {
    const { value } = e.currentTarget;
    if (value) {
      const index = this.props.customer.subs.findIndex((x) => x._id === this.props.match.params.id);

      if (index >= 0) {
        const sub = this.props.customer.subs[index];

        let lastOrder = sub.orders[sub.orders.length - 1];
        lastOrder.nextDelivery = new Date(value);

        this.props.handleChangeShipmentDate(this.props.match.params.id, lastOrder);
      }
    }
  };

  onCancel = () => {
    this.props.handleCancel();
    this.props.history.push("/portal");
  };

  render() {
    const subId = this.props.match.params.id;
    const index = this.props.customer.subs.findIndex((x) => x._id === subId);
    let sub = {};
    let nextDelivery = new Date();

    if (index >= 0) {
      sub = this.props.customer.subs[index];

      let lastOrder = sub.orders[sub.orders.length - 1];
      if (lastOrder) {
        if (lastOrder.nextDelivery) {
          nextDelivery = new Date(lastOrder.nextDelivery);
        }
      }
    }
    let minDelivery = date.format(new Date(), "YYYY-MM-DD");
    let maxDelivery = new Date();
    maxDelivery.setDate(maxDelivery.getDate() + 60);
    maxDelivery = date.format(maxDelivery, "YYYY-MM-DD");

    const { t } = this.props;

    return (
      <div className="editsub-wrapper">
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <h4>{t("subscriptionChangeProduct", { product: sub.product && sub.product.name && getProductDisplayName(sub.product.name) })}</h4>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3 mt-3 col-12">
            <form className="editsub-form" onSubmit={this.onShipmentDateSave}>
              <p>{t("delayDelivery")}</p>
              <p>
                <strong>{t("nextShipment", { when: nextDelivery ?? "oneToFiveDays" })}</strong>
              </p>
              <div className="editsub-form-div">
                <label htmlFor="shipmentdate">{t("chooseNewShipmentDate")}</label>
                <input type="date" name="shipmentdate" value={nextDelivery} onChange={this.onShipmentDateChange} size="10" min={minDelivery} max={maxDelivery}></input>
              </div>
              <div className="editsub-form-buttons">
                <button type="submit" className="btn btn-success">
                  {t("save")}
                </button>
              </div>
            </form>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <button className="btn btn-link" onClick={() => this.props.history.push("/portal/endre/" + this.props.match.params.id + "/avslutt/9999")}>
              {t("cancelSubscription")}
            </button>
            <button className="btn btn-link" onClick={this.onCancel}>
              {t("toMainPage")}
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(EditSub);
