import axios from "axios";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_PORTAL_API + "/sub";
const tokenKey = "usertoken";

axios.interceptors.request.use(
  function (config) {
    if (localStorage.getItem(tokenKey))
      config.headers.common["x-auth-token"] = localStorage.getItem(tokenKey);

    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

export async function activateSub(item) {
  const { data } = await axios.put(apiEndpoint + "/activate", item);
  return data;
}

export async function deactivateSub(item) {
  const { data } = await axios.put(apiEndpoint + "/deactivate", item);
  return data;
}

export async function getReasons(category) {
  const { data } = await axios.get(apiEndpoint + "/reasons/" + category);
  return data;
}

export async function getReason(category, id) {
  const { data } = await axios.get(
    apiEndpoint + "/reasons/" + category + "/" + id
  );
  return data;
}
