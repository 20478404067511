import http from "./httpService";
import jwtDecode from "jwt-decode";

import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_PORTAL_API + "/login";
export const tokenKey = "usertoken";

export async function sendSmsCode(phone, custno) {
  let promise = new Promise(async (resolve, reject) => {
    if (phone && custno) {
      localStorage.removeItem(tokenKey);
      let result = null;
      try {
        result = await http.post(apiEndpoint, { phone, custno });
      } catch (ex) {
        if (ex.response && ex.response.status === 400)
          reject({
            phone: ex.response.data,
            custno: ex.response.data,
          });
      }
      if (result) {
        resolve(result);
      }
    } else {
      reject({
        phone: "noPhone",
        custno: "noCustomerNumber",
      });
    }
  });
  return promise;
}

export async function loginCustomer(phone, custno, code) {
  let promise = new Promise(async (resolve, reject) => {
    if (phone && custno && code) {
      let result = null;
      try {
        result = await http.post(apiEndpoint + "/auth", {
          phone,
          custno,
          code,
        });
      } catch (ex) {
        if (ex.response && ex.response.status === 400)
          reject({
            sms: ex.response.data,
          });
      }
      if (result) {
        resolve(result);
      }
    } else {
      reject({
        sms: "wrongCode",
      });
    }
  });
  return promise;
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const decoded = jwtDecode(jwt);
    if (decoded.iat * 1000 + 3600000 > new Date().getTime()) return decoded;
    else return null;
  } catch (ex) {
    return null;
  }
}
