import React, { Component } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { getProductDisplayName } from "../../../../util/product";
import { withTranslation } from "react-i18next";
import "./invoices.scss";

class Invoices extends Component {
  state = {
    sub: {},
  };

  render() {
    const subId = this.props.match.params.id;
    const index = this.props.customer.subs.findIndex((x) => x._id === subId);
    let sub = {};
    if (index >= 0) sub = this.props.customer.subs[index];
    const { t} = this.props;
    return (
      <div className="invoices-wrapper">
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <h4>
              {t('invoiceOverviewProduct', {product: sub.product && sub.product.name && getProductDisplayName(sub.product.name)})}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t('invoice')}</th>
                  <th>{t('KID-number')}</th>
                  <th>{t('invoiceDate')}</th>
                  <th>{t('deadlineDate')}</th>
                  <th>{t('amount')}</th>
                </tr>
              </thead>
              <tbody>
                {sub.orders &&
                  sub.orders.map((order, index) => {
                    if (order.invoice) {
                      return (
                        <tr key={index}>
                          <td>{order.invoice.id}</td>
                          <td>{order.invoice.kid}</td>
                          <td>
                          {t('formattedDate', {date: new Date(order.invoice.fakturadato)})}
                          </td>
                          <td>
                          {t('formattedDate', {date: new Date(order.invoice.forfallsdato)})}
                          </td>
                          <td>{order.invoice.fullPrice}</td>
                        </tr>
                      );
                    } else
                      return (
                        <tr key={index}>
                          <td colSpan="5">{t('noInvoices')}</td>
                        </tr>
                      );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <button
              className="btn btn-link"
              onClick={() => this.props.history.push("/portal")}
            >
              {t('toMainPage')}
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(Invoices);
