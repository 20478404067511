import React, { Component } from "react";

import "./home.scss";

import Person from "./Person";
import { getProductDisplayName } from "../../../../util/product";
import { withTranslation } from "react-i18next";

class Home extends Component {
  openInvoices = (e, sub) => {
    e.preventDefault();
    this.props.history.push("/portal/faktura/" + sub.sub._id);
  };

  openEditSub = async (e, sub) => {
    e.preventDefault();
    this.props.history.push("/portal/endre/" + sub.sub._id);
  };

  render() {
    const { customer, t } = this.props;
    return (
      <div className="home-wrapper">
        <div className="intro">
          <div>
            <h2>{t("greeting", { user: this.props.user.name })}</h2>
            <h5>{t("discountAnnouncement")}</h5>
            <br />
            <p>{t("welcomeMessage")}</p>
            <ul>
              <li>{t("changeDeliveryDate")}</li>
              <li>{t("changeSubscription")}</li>
            </ul>
          </div>
          <div className="discount-image"></div>
        </div>
        <Person customer={customer} handleUpdatePerson={this.props.handleUpdatePerson} handleInputChange={this.props.handleInputChange} handleCancel={this.props.handleCancel} />
        <div className="subs-wrapper">
          <h4>{t("yourSubscription", { count: customer.subs.length })}</h4>
          <div className="subs">
            {customer.subs.map((sub, index) => {
              let deadline = null;
              let nextDelivery = null;
              let previousDelivery = null;

              if (sub && sub.orders && sub.orders.length > 1) {
                const order = sub.orders[sub.orders.length - 2];

                if (order.shipped_date) {
                  deadline = new Date(order.shipped_date);
                  previousDelivery = new Date(order.shipped_date);
                  if (order.type === "initial") deadline.setDate(deadline.getDate() + sub.product.deadline);
                  else deadline.setDate(deadline.getDate() + sub.product.regularDeadline);
                }
              }

              let lastOrder = sub.orders[sub.orders.length - 1];
              if (lastOrder) {
                if (lastOrder.nextDelivery) {
                  nextDelivery = new Date(lastOrder.nextDelivery);
                }
              }
              if (sub.active)
                return (
                  <div key={index} className="sub">
                    <div className="sub-active">Aktiv</div>
                    <h4>{sub.product.name && getProductDisplayName(sub.product.name)}</h4>
                    <p>
                      {previousDelivery && (
                        <span>
                          {t("lastShipment", { previousDelivery })}
                          <br />
                        </span>
                      )}
                      {t("nextShipment", { when: nextDelivery ?? t("oneToFiveDays") })}
                      <br />
                      {t("terminationDeadline", { deadline })}
                    </p>
                    <button className="btn btn-link" onClick={(e) => this.openEditSub(e, { sub })}>
                      {t("change")}
                    </button>
                    <button className="btn btn-link" onClick={(e) => this.openInvoices(e, { sub })}>
                      {t("invoiceOverview")}
                    </button>
                  </div>
                );
              else
                return (
                  <div key={index} className="sub">
                    <div className="sub-inactive">Inaktiv</div>
                    <h4>{sub.product.name && getProductDisplayName(sub.product.name)}</h4>
                    <button className="btn btn-link" onClick={(e) => this.props.handleActivateSub(e, sub)}>
                      {t("activate")}
                    </button>
                  </div>
                );
            })}
          </div>
        </div>
        <div className="contact-wrapper">
          <h4>{t("questions")}</h4>
          <p>
            {t("contactUs")}
            <br />
            {t("emailSupport")}
          </p>
          <h4>{t("returnRights")}</h4>
          <p>{t("returnDescription1")}</p>
          <p>{t("returnDescription2")}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Home);
