import React, { Component } from "react";
import Joi from "joi-browser";
import { withTranslation } from "react-i18next";
import { sendSmsCode, loginCustomer, tokenKey } from "../../services/loginService";
import "./login.scss";
import LanguageChooser from "../common/LanguageChooser";

class Login extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      login: "login",
      loginData: { phone: "", custno: "" },
      smsData: { sms: "" },
      errors: {},
      loading: false,
    };

    this.loginSchema = {
      phone: Joi.string()
        .required()
        .min(1)
        .label(t("phoneNumber"))
        .error(() => {
          return {
            message: t("invalidPhonenumber"),
          };
        }),
      custno: Joi.number()
        .required()
        .min(100000)
        .max(999999)
        .label(t("customerNumber"))
        .error(() => {
          return {
            message: t("invalidCustomernumber"),
          };
        }),
    };

    this.smsSchema = {
      sms: Joi.number()
        .required()
        .min(10000)
        .max(99999)
        .label(t("smsCode"))
        .error(() => {
          return {
            message: t("invalidSmsCode"),
          };
        }),
    };
  }

  componentDidUpdate() {}

  handleSmsSubmit = async (e) => {
    e.preventDefault();

    const trimPhone = this.state.loginData.phone.replace(/\D/g, "");

    try {
      const response = await loginCustomer(trimPhone, this.state.loginData.custno, this.state.smsData.sms);
      if (response && response.data && response.data.token) {
        localStorage.setItem(tokenKey, response.data.token);
        window.location = "/portal";
        return;
      }
    } catch (ex) {
      const { t } = this.props;
      let errors = this.state.errors;
      errors.sms = t(ex.sms);
      this.setState({ errors, loading: false });
    }
  };

  handleLoginSubmit = async (e) => {
    e.preventDefault();

    let errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({ loading: true });

    // using JS method to remove all non-numeric characters from phone number
    const trimPhone = this.state.loginData.phone.replace(/\D/g, "");

    try {
      let response = await sendSmsCode(trimPhone, this.state.loginData.custno);
      if (response && response.status === 200) {
        if (response.data && response.data.token) {
          localStorage.setItem(tokenKey, response.data.token);
          window.location = "/portal";
          console.log("Logged in!");
          return;
        }

        this.setState({ login: "sms", loading: false });
      }
    } catch (ex) {
      const { t } = this.props;
      let errors = this.state.errors;
      errors.phone = t(ex.phone);
      errors.custno = t(ex.custno);
      this.setState({ errors, loading: false });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const page = this.state.login;
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input, page);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    let data = { ...this.state.loginData };
    if (page === "sms") data = { ...this.state.smsData };

    data[input.name] = input.value;

    if (page === "sms") {
      this.setState({ smsData: data, errors });
      return;
    }

    this.setState({ loginData: data, errors });
  };

  validateProperty = ({ name, value }, page) => {
    const obj = { [name]: value };
    let schema = { [name]: this.loginSchema[name] };

    if (page === "sms") schema = { [name]: this.smsSchema[name] };

    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = (page) => {
    const options = { abortEarly: false };
    let schema = this.loginSchema;
    let data = this.state.loginData;
    if (page === "sms") {
      schema = this.smsSchema;
      data = this.state.smsData;
    }
    const { error } = Joi.validate(data, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  render() {
    const { t } = this.props;
    if (this.state.login === "login") {
      return (
        <div className="login-wrapper">
          <LanguageChooser />
          <div className="login-wrapper-header">
            <img className="logo" src="/images/logo2.png" alt="Norwegian Lab logo" />
            <h5>{t("loginPortal")}</h5>
            <p className="login-subtext">{t("loginHelp")}</p>
          </div>
          <form onSubmit={this.handleLoginSubmit}>
            <div className="login-wrapper-body">
              <div>
                <label htmlFor="phone">{t("phoneNumber")}</label>
                <input
                  className={this.state.errors["phone"] ? "form-control is-invalid" : "form-control"}
                  type="text"
                  name="phone"
                  id="phone"
                  data-testid="phoneInput"
                  value={this.state.loginData.phone}
                  onChange={this.handleChange}
                  error={this.state.errors["phone"]}
                />
                <div className="invalid-feedback">{this.state.errors["phone"]}</div>
              </div>
              <div>
                <label htmlFor="custno">{t("customerNumber")}</label>
                <input
                  className={this.state.errors["custno"] ? "form-control is-invalid" : "form-control"}
                  type="text"
                  name="custno"
                  id="custno"
                  data-testid="custNoInput"
                  value={this.state.loginData.custno}
                  onChange={this.handleChange}
                  error={this.state.errors["custno"]}
                />
                <div className="invalid-feedback">{this.state.errors["custno"]}</div>
              </div>
              <div>
                <button type="submit" data-testid="submitButton" className={this.state.loading ? "btn btn-disabled" : "btn btn-primary"}>
                  {this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : t("sendSmsCode")}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    } else if (this.state.login === "sms") {
      return (
        <div className="login-wrapper">
          <div className="login-wrapper-header">
            <h3>Norwegian Lab</h3>
            <h5>{t("loginPortal")}</h5>
          </div>
          <form onSubmit={this.handleSmsSubmit}>
            <div className="login-wrapper-body">
              <div>
                <label htmlFor="sms">{t("smsCode")}</label>
                <input
                  className={this.state.errors["sms"] ? "form-control is-invalid" : "form-control"}
                  type="text"
                  name="sms"
                  id="sms"
                  value={this.state.smsData.sms}
                  onChange={this.handleChange}
                  error={this.state.errors["sms"]}
                />
                <div className="invalid-feedback">{this.state.errors["sms"]}</div>
              </div>
              <div>
                <button type="submit" className={this.state.loading ? "btn btn-disabled" : "btn btn-primary"}>
                  {this.state.loading ? <i className="fas fa-spinner fa-spin"></i> : t("login")}
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
}

export default withTranslation()(Login);
