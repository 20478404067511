import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./menu.scss";
import { withTranslation } from "react-i18next";
import LanguageChooser from "../../common/LanguageChooser";

class Menu extends Component {
  render() {
    const { location: pathname, t } = this.props;
    return (
      <Navbar bg="white" expand="lg" className="menu">
        <Navbar.Brand href="/portal">
          <img className="menu-logo" src="/images/logo2.png" alt="Norwegian Lab logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <li className={pathname === "/portal" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/portal">
                {t("myPage")}
              </Link>
            </li>
          </Nav>
          <Nav class="nl-language-chooser-menu">
            <LanguageChooser />
          </Nav>
          {this.props.user && (
            <React.Fragment>
              <span className="navbar-text">{t("loginUser", { user: this.props.user.name })}</span>
              <span className={pathname === "/logout" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/logout">
                  {t("logout")}
                </Link>
              </span>
            </React.Fragment>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withTranslation()(Menu);
