import React, { Component } from "react";
import Joi from "joi-browser";
import { withTranslation } from 'react-i18next';
import { loginCustomer } from "../../services/loginService";
import "./login.scss";

class LoginSms extends Component {
  state = {
    data: { sms: "" },
    errors: {},
  };

  schema = {
    sms: Joi.number()
      .required()
      .min(1000)
      .max(9999)
      .label("Sms kode")
      .error(() => {
        return {
          message: "Skriv korrekt kode fra SMS",
        };
      }),
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    try {
      await loginCustomer(
        this.state.data.phone,
        this.state.data.custno,
        this.state.data.sms
      );
    } catch (ex) {
      let errors = this.state.errors;
      errors.phone = ex.phone;
      errors.custno = ex.custno;
      this.setState({ errors });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };

    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  render() {
    const { t } = this.props;
    return (
      <div className="login-wrapper">
        <div className="login-wrapper-header">
          <h3>Norwegian Lab</h3>
          <h5>{t('loginPortal')}</h5>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="login-wrapper-body">
            <div>
              <label htmlFor="sms">{t('smsCode')}</label>
              <input
                className={
                  this.state.errors["sms"]
                    ? "form-control is-invalid"
                    : "form-control"
                }
                type="text"
                name="sms"
                id="sms"
                onChange={this.handleChange}
                error={this.state.errors["sms"]}
              />
              <div className="invalid-feedback">{this.state.errors["sms"]}</div>
            </div>
            <div>
              <button type="submit" className="btn btn-primary">
                {t('login')}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(LoginSms);
