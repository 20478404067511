import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const nlLanguageDetector = {
  name: "nlLanguage",

  lookup(options) {
    const hostname = window?.location?.hostname;
    switch (hostname) {
      case "minasidor.norwegianlab.com":
      case "minasidor.norwegianlab.se":
        return "sv";
      case "minside.norwegianlab.com":
      case "minside.norwegianlab.no":
        return "nb";
      case "minside.norwegianlab.dk":
        return "da";
      case "asiakassivut.norwegianlab.com":
      case "asiakassivut.norwegianlab.fi":
        return "fi";
      case "meinkonto.norwegianlab.de":
      case "meinkonto.norwegianlab.com":
        return "de";
      case "mijnaccount.norwegianlab.nl":
      case "mijnaccount.norwegianlab.com":
        return "nl";
      case "moncompte.norwegianlab.fr":
        return "fr";
      case "konto.norwegianlab.pl":
        return "pl";
      case "moje.norwegianlab.cz":
        return "cz";
      case "minhaconta.norwegianlab.pt":
        return "pt";
      case "myaccount.norwegianlab.net":
      case "myaccount.norwegianlab.com":
        return "en";
      default:
        console.log("Could not find language for hostname", hostname);
        return "nb";
    }
  },

  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(nlLanguageDetector);

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    keySeparator: ">",
    nsSeparator: "|",

    fallbackLng: "nb",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (format === "intlDate" && value instanceof Date) {
          return new Intl.DateTimeFormat(lng).format(value);
        }

        return value;
      },
    },

    //LanguageDetector options
    detection: {
      order: ["querystring", "cookie", "localStorage", "sessionStorage", "nlLanguage"],
    },
  });

export default i18n;
