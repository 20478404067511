import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class CancelSubThanks extends Component {
  state = {
    deadline: "",
  };

  componentDidMount() {
    const index = this.props.customer.subs.findIndex(
      (x) => x._id === this.props.match.params.id
    );
    if (index >= 0) {
      const sub = this.props.customer.subs[index];
      let productText;

      if (
        sub.product.name === "Hair And Skin" ||
        sub.product.name === "Hair And Skin 2" ||
        sub.product.name === "Melatonin Vital" ||
        sub.product.name === "Mory" ||
        sub.product.name === "Mory 2" ||
        sub.product.name === "Energy B12D3" ||
        sub.product.name === "T-Complex" ||
        sub.product.name === "Craveless"
      ) {
        productText = this.props.t("unsubDescription1");
      } else {
        productText = this.props.t("unsubDescription2")
      }

      this.setState({ productText });

      let deadline = null;

      if (sub && sub.orders && sub.orders.length > 1) {
        const order = sub.orders[sub.orders.length - 2];
        if (order.shipped_date) {
          deadline = new Date(order.shipped_date);
          if (order.type === "initial")
            deadline.setDate(deadline.getDate() + sub.product.deadline + 1);
          else
            deadline.setDate(
              deadline.getDate() + sub.product.regularDeadline + 1
            );
          this.setState({ deadline });
        }
      }
    }
  }

  render() {
    let today = new Date();
    const { t } = this.props;
    return (
      <div className="editsub-wrapper">
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <h4>{t('sadGoodbye', {name: this.props.customer.name})}</h4>
            <p>{t('subscriptionStopped')}</p>
            {this.state.deadline && this.state.deadline < today && (
              <p>{this.state.productText}</p>
            )}
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12"></Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="mb-3 mt-3 col-12">
            <button
              className="btn btn-link"
              onClick={() => this.props.history.push("/portal")}
            >
              {t('toMainPage')}
            </button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(CancelSubThanks);
