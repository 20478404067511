import axios from "axios";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const apiEndpoint = env.REACT_APP_PORTAL_API + "/customer";
const tokenKey = "usertoken";

axios.interceptors.request.use(
  function(config) {
    if (localStorage.getItem(tokenKey))
      config.headers.common["x-auth-token"] = localStorage.getItem(tokenKey);

    return Promise.resolve(config);
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export async function getCustomer() {
  const { data } = await axios.get(apiEndpoint);
  return data;
}

export async function putCustomer(customer) {
  const { data } = await axios.put(apiEndpoint, customer);
  return data;
}
